import React, { useState } from 'react'
// import Confetti from 'react-confetti';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header'
import Home from './pages/Home'
import AllCourse from './pages/AllCourse'
import Footer from './components/Footer'
import Progressbar from './components/Progressbar'
// import Sidebar from './admin/Sidebar'
// import Admin from './admin/Admin'
// import Terms from './pages/Terms'
// import Privacy from './pages/Privacy'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import About from './pages/About'
import Contact from './pages/Contact'
import Manoj from './components/mentors/Manoj'
import Arvind from './components/mentors/Arvind'
import Direction from './pages/course/Direction'
import Admission from './pages/Admission'
import Cinematography from './pages/course/Cinematography'
import Editing from './pages/course/Editing'
import Acting from './pages/course/Acting'
import Vfx from './pages/course/Vfx'
import MultiForm from './pages/MultiForm'
import Stanley from './components/mentors/Stanley'
import StillPhotography from './pages/course/StillPhotography'


import manoj from './images/banner/entry.jpg'
import mobilemonoj from './images/newMobile.png'
import ban from './images/2.png'

import logo from './images/footer_logo-1.png'
import Madhu from './components/mentors/Madhu'
import VirtualProduction from './pages/course/VirtualProduction'
import UserDetail from './components/mentors/UserDetails'
import PaymentPage from './pages/PaymentPage'
import StageUnreal from './pages/course/StageUnreal'
import CFA from './pages/course/CFA'
import RajKumar from './components/mentors/RajKumar'
import Prasanna from './components/mentors/Prasanna'
import Sirinivas from './components/mentors/Sirinivas'
import MultiStepForm from './pages/form/MultiStepForm'
import AranthaiManiyan from './components/mentors/AranthaiManiyan'
import RajaMohammad from './components/mentors/RajaMohammad'
import MuniRaj from './components/mentors/Muniraj'
import DI from './pages/course/DI'
import Bebin from './components/mentors/Bebin'
import Workshop from './pages/Workshop'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import Refund from './pages/Refund'
import BlogMain from './pages/blog/BlogMain'
import Popup from './pages/Popup'

// import Refund from './pages/Refund';


const App = () => {

  const [showImage, setShowImage] = useState(false);
  // const [showConfetti, setShowConfetti] = useState(false);

  const handleImageClick = () => {
    setShowImage(true);

  };

  // setTimeout(() => {
  //   setShowConfetti(false);
  // }, 10000); // 15000 milliseconds = 15 seconds


  const handleImageOk = () => {
    window.location.href = '/'; // Redirect to home page
  };


  return (


    <>

      {/* {!showImage && (
        <div className='flex justify-center items-center font-[Cardo]'>

          <div className='fixed top-0 -z-20 bg-black/90 w-full'>
            <img src={ban} className='w-full object-cover opacity-50 h-screen md:h-auto ' alt="" />
          </div>


          <div className='w-full z-10'>

            <div className='w-full  fixed top-0 py-3 px-4  md:px-8'>
              <img src={logo} className='object-cover  w-52 md:w-60 ' alt="" />
            </div>


            <div className=''>
              <div className='w-full  h-screen bg-orange-600/55 md:bg-transparent flex flex-col-reverse md:flex-row justify-center items-center'>

                <div className=' relative w-full'>

                  <div className='flex md:hidden'>
                    <img
                      src={mobilemonoj}
                      alt="Placeholder"
                      className="mx-auto w-full  h-screen  drop-shadow-lg object-cover cursor-pointer"
                      onClick={handleImageOk}
                    />
                  </div>

                  <div className='hidden md:flex'>
                    <img
                      src={manoj}
                      alt="Placeholder"
                      className="mx-auto w-full h-screen drop-shadow-lg object-cover cursor-pointer"
                    
                    />
                  </div>


                  <div className=' justify-end absolute w-[82%]  bottom-0 hidden md:flex '>
                    <button
                      onClick={handleImageClick}
                      className="border border-white hover:border-[#ddb100] bg-[#ddb100] text-white px-3 py-2 md:px-6 md:py-2 h-fit rounded m-2 hover:bg-transparent  animate-bounce duration-700 delay-300 text-[14px] md:text-[16px] font-semibold"
                    >
                      Your Flimmaking Journey Begins Now!
                    </button>
                  </div>

                 

                  <div className=' justify-end absolute w-[88%]  top-80 flex md:hidden'>
                    <button
                      onClick={handleImageClick}
                      className="border border-white hover:border-[#ddb100] bg-[#ddb100] text-white px-3 py-2 md:px-6 md:py-3 h-fit rounded m-2 hover:bg-transparent animate-bounce duration-700 delay-300 font-[] text-[14px] md:text-[16px] font-semibold"
                    >
                      Your Flimmaking Journey Begins Now!
                    </button>
                  </div>


                </div>


             

              </div>

            </div>
          </div>
        </div>

      )} */}


      {/* {showImage && ( */}
      <>
      
        <HelmetProvider>
          <Router className=''>
            <Progressbar />

            <Header />

            <ToastContainer position="top-right" />
            <Routes>
              <Route path='/' element={<Home />} />

              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/admission' element={<Admission />} />
              <Route path='/apply' element={<MultiStepForm />} />
              <Route path='/workshops' element={<Workshop />} />
              <Route path='/cinema_factory_blog' element={<BlogMain />} />
              <Route path='/terms&conditions' element={<Terms />} />
              <Route path='/privacy_policy' element={<Privacy />} />
              <Route path='/cancellation&refund' element={<Refund />} />


              {/* ----------------------------- mentors pages ----------------------- */}

              {/* <Route path='manoj_paramahamsa' element={<Manoj />} /> */}
              {/* <Route path='madhu_ambat' element={<Madhu />} /> */}
              {/* <Route path='aranthai_maniyan' element={<AranthaiManiyan />} /> */}
              {/* <Route path='aravind' element={<Arvind />} /> */}
              {/* <Route path='stanley' element={<Stanley />} /> */}
              {/* <Route path='cj_rajkumar' element={<RajKumar />} /> */}
              {/* <Route path='prasanna_venkatesh' element={<Prasanna />} /> */}
              {/* <Route path='sirinivas_mohan' element={<Sirinivas />} /> */}
              {/* <Route path='raja_mohammad' element={<RajaMohammad />} /> */}
              {/* <Route path='muniraj' element={<MuniRaj />} /> */}
              {/* <Route path='bebin' element={<Bebin />} /> */}

              <Route path="/:username" element={<UserDetail />} />


              {/* -------- courses pages --------- */}

              <Route path='/courses' element={<AllCourse />} />
              <Route path='/direction' element={<Direction />} />
              <Route path='/cinematography' element={<Cinematography />} />
              <Route path='/editing' element={<Editing />} />
              <Route path='/acting' element={<Acting />} />
              <Route path='/vfx' element={<Vfx />} />
              <Route path='/photography' element={<StillPhotography />} />
              <Route path='/virtual_production' element={<VirtualProduction />} />
              <Route path='/di' element={<DI />} />
              <Route path='/virtual_production/stage_unreal' element={<StageUnreal />} />
              <Route path='/virtual_production/cfa' element={<CFA />} />

              <Route path='/payment' element={<PaymentPage />} />



              {/* <Route path="/admin/*" element={<Admin />} /> */}

            </Routes>

            <Footer />

          </Router >
        </HelmetProvider>

      </>
      {/* )} */}
    </>

  )
}


export default App
